@charset "utf-8";
//======================================================
//
//  変数、mixin、extend CSS
//
//======================================================
//--------------------------------------------//
//  変数
//--------------------------------------------//
//基本色
$color-jtb-red:#cc0d21;
$color-jtb-secondary-red: #d94f5d;
$color-cv-red:#800;
$color-red:#c81528;
$color-search-red:transparent linear-gradient(132deg, #cc0d21 0%, #ec6496 100%) 0% 0% no-repeat padding-box;
$color-icon-red:transparent linear-gradient(132deg, #D94F5D 0%, #D94F5D99 100%) 0% 0% no-repeat padding-box;
$color-primary-blue:#3498DB;
$color-blue:#52C8FF;
$color-navy-blue:#181878;
$color-vivid-blue:#0012ff;
$color-light-blue:#ecf9ff;
//本文テキストカラー
$color-txt-normal: #323743;
$color-txt-black: rgba(0, 0, 0, 0.87);
$color-text-warning: #E18D52;
//リンクカラー
$color-link:#3498db;
//ボタンカラー
$color-btn: transparent linear-gradient(112deg, #3498DB 0%, #52C8FF 100%) 0% 0% no-repeat padding-box;
$color-check: transparent linear-gradient(180deg, #3498DB 0%, #52C8FF 100%) 0% 0% no-repeat padding-box;
$color-step: transparent linear-gradient(112deg, #3498DB 0%, #52C8FF 100%) 0% 0% no-repeat padding-box;
$color-step-reverse: transparent linear-gradient(112deg, #52C8FF 0%, #3498DB 100%) 0% 0% no-repeat padding-box;
//JTB-Design-Sysytem_afterPh3
$primary_black:#323743;
$dark_gray:#656972;
$neutral_gray:#989ba1;
$line_gray:#bcbdc2;
$bg_gray:#f4f5f5;
$light_gray:#f8f9f9;
$primary_blue:#3498db;
$light_blue:#ecf9ff;
$primary_red:#cc0000;
$secondary_red:#d94f5d;
$light_red:#fbedef;
$primary_yellow:#e18d52;
$light_yellow:#fef6f0;
$primary_green:#59bb44;
$light_green:#D6F5D3;
//その他の設定
$color-overlay:rgba(#000, .5);
$color-bg-error:#fbedef;
$color-bg-warning:#FDE9DA;
$color-btn-disabled:#dfe2e5; //#bbb
$color-txt-disabled:#95999e; //#aaa
$color-border-gray:#C0C5CB;
//width
$max-width:116rem;
$min-width:97rem;
$midlle-width:108rem;

//--------------------------------------------//
//  mixin
//--------------------------------------------//
@mixin mq($point) {
	@if $point==pc {

		@media (orientation: portrait) and (min-width: 600px),
			(orientation: landscape) and (min-width: 897px) {
			@content ;
		}
	}

	@else if $point==sp {

		@media (orientation: portrait) and (max-width: 599px),
			(orientation: landscape) and (max-width: 896px) {
			@content ;
		}
	}
}

//  アイコンフォント - before
%ico_font {
	display: inline-block;
	font-family: "kkrs-dp";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-rendering: auto;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin ico_font($code) {
	content: $code;
	@extend %ico_font;
}

//スマホ用アイコンフォント
@media (orientation: portrait) and (max-width: 599px),
(orientation: landscape) and (max-width: 896px) {
	%ico_fontSP {
		display: inline-block;
		font-family: "kkrs-dp";
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin sp_ico_font($code) {
	content: $code;
	@extend %ico_fontSP;
}

//Material Icon
%material_icon {
	display: inline-block;
	font-family: "Material Icons Round";
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	// Support for all WebKit browsers
	-webkit-font-smoothing: antialiased;
	// Support for Safari and Chrome
	text-rendering: optimizeLegibility;
	// Support for Firefox
	-moz-osx-font-smoothing: grayscale;
	// Support for IE
	font-feature-settings: 'liga';
}

@mixin material_icon($code) {
	content: $code;
	@extend %material_icon;
}

//スマホ用Material Icon
@include mq(sp) {
	%material_iconSP {
	display: inline-block;
	font-family: "Material Icons Round";
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	// Support for all WebKit browsers
	-webkit-font-smoothing: antialiased;
	// Support for Safari and Chrome
	text-rendering: optimizeLegibility;
	// Support for Firefox
	-moz-osx-font-smoothing: grayscale;
	// Support for IE
	font-feature-settings: 'liga';
	}
}

@mixin sp_material_icon($code) {
	content: $code;
	@extend %material_iconSP;
}

//position
@mixin abs_tl($top: 0, $left: 0) {
	position: absolute;
	top: $top;
	left: $left;
}

@mixin abs_tr($top: 0, $right: 0) {
	position: absolute;
	top: $top;
	right: $right;
}

@mixin abs_bl($bottom: 0, $left: 0) {
	position: absolute;
	bottom: $bottom;
	left: $left;
}

@mixin abs_br($bottom: 0, $right: 0) {
	position: absolute;
	bottom: $bottom;
	right: $right;
}

//clearfix
@mixin clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

//hover opacity
@mixin hover-opacity {
	transition: opacity .2s;

	&:hover {
		opacity: .8;
	}
}

//flex
@mixin flex($wrap) {
	display: flex;
	flex-wrap: $wrap;
}

@mixin flex_al_center($wrap) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: center;
}

@mixin flex_al_right($wrap) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: flex-end;
}

@mixin flex_al_between($wrap) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: space-between;
}

@mixin flex_val_top($wrap) {
	display: flex;
	flex-wrap: $wrap;
	align-items: flex-start;
}

@mixin flex_val_center($wrap) {
	display: flex;
	flex-wrap: $wrap;
	align-items: center; //縦中央
}

@mixin flex_val_bottom($wrap) {
	display: -webkit-flex;
	display: flex;
	flex-wrap: $wrap;
	align-items: flex-end;
}

//  ボタン - お気に入りボタン
@mixin btn_favorite {
	width: 4rem;
	height: 4rem;
	background-color: #fff;
	border-radius: 50%;
	position: relative;

	&:hover {
		//&:not(.kkrs-active):hover 以前はアクティブ表示は非表示にしていた
		opacity: 1;

		span {
			opacity: 1;
			visibility: visible;
		}
	}

	&:before {
		@include ico_font("\e943");
		font-size: 2.4rem;
		line-height: 1;
		@include abs_tl(1rem, 50%); //アイコンが上寄りに錯覚するためtop少しずらす
		margin-left: -.5em;
		transition: color .2s;
	}

	&.kkrs-active {
		&:before {
			content: "\e91d";
			color: #D02335;
		}
	}

	//ツールチップ
	span {
		opacity: 0;
		visibility: hidden;
		display: block;
		background-color: #fff;
		font-size: 1.1rem;
		line-height: 1.4;
		border: 1px solid $color-border-gray;
		padding: .5rem 1rem;
		box-shadow: 0 3px 6px 0 rgba(#000, .2);
		@include abs_br(100%, 0);
		white-space: nowrap;
		transition: all .2s;

		&:before {
			content: "";
			border-style: solid;
			border-width: 5px;
			border-color: #fff #fff transparent transparent;
			@include abs_tr(100%, 2rem);
			z-index: 2;
		}

		&:after {
			content: "";
			border-style: solid;
			border-width: 6px;
			border-color: #000 #000 transparent transparent;
			@include abs_tr(100%, 1.9rem);
			z-index: 1;
		}
	}

	@media (orientation: portrait) and (max-width: 599px),
	(orientation: landscape) and (max-width: 896px) {
		&:hover {
			opacity: 1;
		}

		span {
			display: none;
		}
	}
}

// グラデーションボーダー
@mixin btn-border {
	&:hover {
		&::after {
			transition: .3s;
			border: inherit;
			border-radius: 40px;
		}

		opacity: 1;
		transition: .3s;
		border-radius: 40px;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		transition: .3s;
	}

	&::after {
		width: calc(100% - .6rem);
		height: calc(100% - .6rem);
		z-index: -2;
		background: #fff;
		margin: .3rem;
	}

	transition: .3s;
	background: $color-btn;
	color: $color-primary-blue;
	position: relative;
	z-index: 0;
}

// グラデーションボーダー（角丸）
@mixin step_border {

	&::before,
	&::after {
		border-radius: 50%;
	}

	z-index: 1;
}

@mixin btn-radius {
	&:hover {
		border-radius: 40px;
		transition: .3s;
		opacity: 1;
	}

	transition: .3s;
}

@mixin btn-underline {
	color: $color-primary-blue;
	position: relative;

	&:before {
		margin-top: -0.85em;
	}

	&:after {
		margin-top: .5rem;
		height: .2rem;
		content: "";
		display: block;
		width: 0;
		background: $color-btn;
		transition: .3s;
	}

	&:hover {
		&:after {
			width: 100%;
			transition: .3s;
		}
	}
}

@mixin btn-ripple($color, $size: null) {
	position: relative;
	overflow: hidden;

	/*クリックした後で青い波紋が広がる*/
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		background: $color;
		border-radius:50%;
		opacity: .6;
		transform: translate(-50%, -50%) scale(0);
		width: 110%;
		padding-top: 100%;
	}

	&:not(:active):before {
		animation: ripple 1s ease-out;
	}

	/*ページが最初に読み込まれた時に波紋が出ない*/
	&:before {
		visibility: hidden;
	}

	/*マウスオーバーで波紋が出たら見えるようにする*/
	&:focus:before {
		visibility: visible;
	}
}

@mixin btn-disabled-normal {
	&.is-disabled {
		pointer-events: none;
		cursor: initial;
		color: #95999E;
		background: rgba(192, 197, 203, .4);
	}
}

@mixin btn-disabled-text {
	&.is-disabled {
		pointer-events: none;
		color: #95999E;
	}
}

//  アニメーション
//----------------------------------------------
@mixin anm-ripple {

	/*波紋のアニメーション*/
	@keyframes ripple {
		0% {
			transform: translate(-50%, -50%) scale(0);
		}

		20% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			opacity: 0;
			transform: translate(-50%, -50%) scale(1);
		}
	}
}
	@keyframes ripple {
		0% {
			transform: translate(-50%, -50%) scale(0);
		}

		20% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			opacity: 0;
			transform: translate(-50%, -50%) scale(1);
		}
	}
//アラート表示
@mixin kkrs-alert-txt {
	display: inline-block;
	color: $color-jtb-red;

	&:before {
		@include ico_font("\e920");
		margin-right: .5em;
	}
}

@mixin kkrs-alert-box {}
