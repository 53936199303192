/* JTBツアー タブ
---------------------------------------------------------- */
.kkrs-search-tab {
	box-sizing: border-box;

	>* {
		box-sizing: border-box;
	}

	&__list {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: 0;
		padding: 0;
	}
}

.kkrs-search-tab__list>li {
	width: calc(50% - 6px);
	margin: 0;
	list-style: none;
}

.kkrs-search-tab__list>li>a {
	display: flex;
	align-items: center;
	background: linear-gradient(112deg, #d94f5d 0%, #d94f5d 100%);
	justify-content: center;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
	position: relative;
}

.kkrs-search-tab__list>li>a:not(.kkrs-active) {
	color: #fff;
	padding: 19px 20px;
}

.kkrs-search-tab__list>li>a.kkrs-active {
	background: rgba(255, 255, 255, 0.9);
	color: #d94f5d;
	padding: 25px 20px;
}

.kkrs-search-tab__list>li>a.kkrs-active:hover {
	opacity: 1;
}

.kkrs-search-tab__list>li>a.kkrs-active:before {
	content: "";
	display: block;
	width: 100%;
	height: 5px;
	position: absolute;
	bottom: 100%;
	left: 0;
	background: linear-gradient(112deg, #d94f5d 0%, #d94f5d 100%);
}

.kkrs-search-tab__list>li>a .kkrs-search-tab__btn:before {
	@include ico_font("\e960");
	margin-right: .5em;
}

.kkrs-search-tab__list>li.kkrs-air>a .kkrs-search-tab__btn:before {
	content: "\e918\e960";
}

.kkrs-search-tab__list>li.kkrs-jr>a .kkrs-search-tab__btn:before {
	content: "\e969\e960";
}

.kkrs-search-tab__box {
	background: rgba(255, 255, 255, 0.9);
}

@include mq(sp) {
	.kkrs-search-tab__list>li {
		width: calc(50% - 4px);
	}

	.kkrs-search-tab__list>li>a {
		font-size: 14px;
	}

	.kkrs-search-tab__list>li>a:not(.kkrs-active) {
		padding: 10px 5px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active {
		padding: 14px 5px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active:before {
		height: 4px;
	}

	.kkrs-search-tab__list>li>a .kkrs-search-tab__btn:before {
		margin: 0 0 6px 0;
		display: block;
		font-size: 16px;
	}
}

//--------------------------------------------
//標準横型 horizontal
//--------------------------------------------
/* 標準横型 */
.kkrs-horizontal {
	padding: 20px;
	background: #fff;

	@include mq(pc) {
		width: 480px;
	}

	.kkrs-search-tab__list {
		margin-bottom: 20px;
	}

	.kkrs-search-tab__list>li {}

	.kkrs-search-tab__list>li>a {
		font-size: 14px;
		background: transparent;
	}

	.kkrs-search-tab__list>li>a:not(.kkrs-active) {
		color: $color-txt-normal;
		padding: 5px 0 15px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active {
		background: transparent;
		padding: 5px 0 15px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active:before {
		height: 3px;
		bottom: 0;
	}

	.kkrs-search-tab__list>li>a.kkrs-active:after {
		content: "";
		position: absolute;
		left: calc(50% - 3px);
		bottom: -6px;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid #d94f5d;
	}

	.kkrs-search-tab__box {
		background: transparent;
	}
}

//--------------------------------------------
//標準縦型 vertical
//--------------------------------------------
/* 標準縦型 */
.kkrs-vertical {
	padding: 20px;
	background: #fff;

	@include mq(pc) {
		width: 360px;
	}

	.kkrs-search-tab__list {
		margin-bottom: 20px;
	}

	.kkrs-search-tab__list>li {}

	.kkrs-search-tab__list>li>a {
		font-size: 13px;
		background: transparent;
	}

	.kkrs-search-tab__list>li>a:not(.kkrs-active) {
		color: $color-txt-normal;
		padding: 0 0 15px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active {
		background: transparent;
		padding: 0 0 15px;
	}

	.kkrs-search-tab__list>li>a.kkrs-active:before {
		height: 3px;
		bottom: 0;
	}

	.kkrs-search-tab__list>li>a.kkrs-active:after {
		content: "";
		position: absolute;
		left: calc(50% - 3px);
		bottom: -6px;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid #d94f5d;
	}

	.kkrs-search-tab__list>li>a .kkrs-search-tab__btn:before {
		margin: 0 0 8px 0;
		display: block;
		font-size: 15px;
	}

	.kkrs-search-tab__box {
		background: transparent;
	}
}