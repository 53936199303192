/* error
---------------------------------------------------------- */
//--------------------------------------------------
//選択ボックスのエラー
//--------------------------------------------------
.kkrs-error-message {
	background-color: #fff;
	box-shadow: 0 5px 10px 0 rgba($color-txt-normal, .4);
	color: #cc0d21;
	display: block;
	font-size: 11px;
	line-height: 1;
	position: absolute;
	top: calc(100% + 4px);
	left: 0;
	padding: .6em .8em;
	text-align: center;
	z-index: 8;
}