/* form
---------------------------------------------------------- */
//
//checkbox
//--------------------------------------------
.kkrs-form-checkbox {
	position: relative;

	input {
		display: none;
	}

	label {
		display: block;
		padding: 5px 5px 5px 24px;
		transition: opacity .2s;

		&:before {
			content: "";
			width: 16px;
			height: 16px;
			display: block;
			border: 1px solid #999;
			background-color: #fff;
			border-radius: 2px;
			@include abs_tl(50%, 0);
			margin-top: -8px;
		}
	}

	//if checked
	input:checked+label {
		&:before {
			background: $color-check;
		}

		&::after {
			content: "";
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
			width: 8px;
			height: 4px;
			transform: rotate(-45deg);
			top: 0;
			left: 4px;
			bottom: 0;
			margin: auto;
			display: block;
			position: absolute;
		}
	}

	//if disabled
	input:disabled+label {
		color: #999;
		cursor: default;

		&:before {
			border-color: rgba(#999, .5);
		}
	}
}

//
//radio
//--------------------------------------------
.kkrs-form-radio {
	position: relative;

	input {
		display: none;
	}

	label {
		display: block;
		padding: 5px 5px 5px 24px;
		transition: opacity .2s;

		&:before {
			content: "";
			width: 16px;
			height: 16px;
			display: block;
			border: 1px solid #999;
			background-color: #fff;
			border-radius: 50%;
			@include abs_tl(50%, 0);
			margin-top: -8px;
		}
	}

	//if checked
	input:checked+label {
		&:before {
			background: $color-btn;
			box-shadow: 0 0 0 2px #fff inset;
		}
	}

	//if disabled
	input:disabled+label {
		color: #999;
		cursor: default;

		&:before {
			border-color: rgba(#999, .5);
		}
	}
}

//
//pulldown（select風）
//--------------------------------------------
.kkrs-form-pulldown {
	position: relative;
	z-index: 9;

	&__set {
		min-width: 10px;
		height: 54px;
		display: flex;
		align-items: center;
		padding: 0 30px 0 10px;
		cursor: pointer;
		transition: opacity .2s;
		position: relative;
		border: 1px solid $line_gray;
		background: #fff;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 16px;

		&:before {
			@include ico_font("\e903");
			font-size: 13px;
			@include abs_tr(50%, 10px);
			margin-top: -.5em;
			transition: transform .2s;
		}
	}

	&__panel {
		display: none;
		@include abs_tl(100%, 0);
		z-index: 1;
		width: 100%;
		margin-top: -1px;
		background-color: #fff;
		border: 1px solid #999;
	}

	&.kkrs-active {
		z-index: 101;

		.kkrs-form-pulldown__set {
			&:before {
				transform: rotate(180deg);
			}
		}

		.kkrs-form-pulldown__panel {
			display: block;
		}
	}
}

//kkrs-form-pulldown-list
.kkrs-form-pulldown-list {
	margin: 0;
	padding: 0;
	max-height: 300px;
	overflow: auto;
	overflow-x: hidden;

	>li {
		margin: 0;
		cursor: pointer;
		transition: background .2s;
		line-height: 1;
		padding: 12px 10px;

		&:hover {
			background: $color-light-blue;
		}

		&.kkrs-active {
			background: $color-light-blue;
		}

		&.kkrs-disabled {
			background: $color-btn-disabled;
			color: $color-txt-disabled;
			//pointer-events: none;
		}
	}
}

@include mq(sp) {
	.kkrs-form-pulldown {
		&__set {
			height: 48px;
			font-size: 16px;
		}
	}

	//kkrs-form-pulldown-list
	.kkrs-form-pulldown-list {
		max-height: 230px;

		>li {
			font-size: 16px;
		}
	}
}

//
//kkrs-input-adornment
//--------------------------------------------
.kkrs-input-adornment {
	display: block;
}

.kkrs-input-clear-btn {
	min-width: 36px;
	height: 100%;
	font-size: 18px;
	position: relative;

	span {
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			@include material_icon("\e5cd");
			color: #95999e;
		}
	}
}