@font-face {
	font-family: 'kkrs-dp';
	src:
		url('../../fonts/2.0/kkrs-dp.ttf') format('truetype'),
		url('../../fonts/2.0/kkrs-dp.woff') format('woff'),
		url('../../fonts/2.0/kkrs-dp.svg#kkrs-dp') format('svg');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round');