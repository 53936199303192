/* kkrs-top-search-section
---------------------------------------------------------- */
.kkrs-top-search-section {
	color: $color-txt-normal;
	font-size: 14px;
	line-height: 1.7;
	padding: 20px;

	//リセット------------------------
	ul,
	li {
		list-style: none;
	}

	p {
		margin: 0;
	}

	*,
	*:after,
	*:before {
		box-sizing: border-box;
	}

	button {
		-webkit-appearance: none;
		appearance: none;
		border: none;
		background: transparent;
		color: inherit;
		cursor: pointer;
	}

	div:focus,
	button:focus,
	input:focus,
	textarea:focus,
	select:focus {
		outline: none;
	}

	:-webkit-input-placeholder {
		line-height: 1;
		padding-top: .1em;
		color: #95999e;
	}

	:-ms-input-placeholder {
		color: #95999e;
	}

	//共通設定----------------------
	.kkrs-sp {
		display: none;
	}

	.kkrs-hide {
		display: none !important;
	}

	//検索コンテナ------------------------
	.kkrs-search-wrap {
		&__contents {}

		&__navi {
			margin-top: 10px;
			text-align: center;
		}
	}

	//検索ボタン
	.kkrs-top-search-submit-btn {
		background: $color-search-red;
		width: 300px;
		font-size: 18px;
		line-height: 1;
		font-weight: bold;
		color: #fff;
		padding: 1em;
		height: 54px;
		letter-spacing: .2em;
		@include btn-radius;
		@include btn-ripple(#ffd2d2, 60);

		span {
			display: flex;
			justify-content: center;
			align-items: center;

			&:before {
				@include material_icon("\e8b6");
				margin-right: 10px;
				font-weight: 400;
			}
		}
	}

	//kkrs-search-list 検索リスト------------------------
	.kkrs-search-list {
		@include flex_val_top(wrap);
		justify-content: space-between;

		.kkrs-alert-box {
			width: 100%;

			.kkrs-alert-txt {
				text-align: left;
				display: block;
			}
		}
	}

	.kkrs-search-item {
		width: calc(33.3333% - 20px);
		margin-bottom: 20px;
		position: relative;

		//A-00 エラー時にフォームのborderを変更(kkrs-search-itemに対してはafterを設定しない)
		&.kkrs-error {
			.kkrs-search-input {
				border: 1px solid #cc0d21;
			}
		}

		&.kkrs-disabled {
			pointer-events: none;

			.kkrs-search-input,
			.kkrs-form-pulldown__set {
				background: $color-btn-disabled;
				color: $color-txt-disabled;

				&:after {
					color: $color-txt-disabled;
				}

				input {
					background: transparent;
					color: $color-txt-disabled;
				}
			}
		}

		.kkrs-search-label {
			font-weight: 700;
			font-size: 12px;
			line-height: 1;
			color: $neutral_gray;
			position: absolute;
			top: 8px;
			left: 38px;
			z-index: 1;
			display: none;
		}

		.kkrs-search-column {
			position: relative;
			height: 56px; //IE11で高さの違いがでるため

			//A-00 フォームのエラー時にbackground-colorを変更しない
			&.kkrs-error {
				.kkrs-search-input {
					background-color: inherit;
				}
			}
		}

		.kkrs-search-input {
			display: flex;
			position: relative;
			border: 1px solid $line_gray;
			background: #fff;

			//アイコン
			&:after {
				color: $dark_gray;
				position: absolute;
				top: 0;
				left: 0;
				width: 38px;
				height: 100%;
				font-size: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			input {
				border: none;
				height: 54px;
				width: 100%;
				padding: 0 10px;
				font-size: 16px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&:not(:nth-child(2)) {
					padding-left: 38px;
				}
			}
		}

		.kkrs-input-adornment {
			display: block;
		}

		.kkrs-input-clear-btn {
			min-width: 36px;
			height: 100%;
			font-size: 18px;
			position: relative;

			span {
				display: flex;
				align-items: center;
				justify-content: center;

				&:before {
					@include material_icon("\e5cd");
					color: #95999e;
				}
			}
		}

		//出発地,目的地
		.kkrs-search-input--place {
			&:after {
				@include material_icon("\e55f");
			}

			input {
				padding-top: 20px;
			}
		}

		//旅行期間
		.kkrs-search-input--period {
			display: flex;

			&:after {
				@include material_icon("\e8df");
			}

			//矢印（→）を表示
			&:before {
				@include ico_font("\e908");
				@include abs_tl(50%, calc(50% - 15px));
				transform: translate(-50%, -50%);
			}

			input {
				width: calc(50% - 30px);
				padding-right: 0;
			}

			input+input {
				margin-left: 30px;
			}
		}

		//チェックイン日
		.kkrs-search-input--date {
			&:after {
				@include material_icon("\e8df");
			}

			//×ボタンのツールチップの位置調整
			.kkrs-search-clear-tooltip .kkrs-tooltip-wrap {
				line-height: 1;
				bottom: calc(100% - 10px);
				right: -20px;
			}
		}

		//部屋数・人数
		.kkrs-search-input--people {
			&:after {
				@include material_icon("\e7fd");
			}
		}

		//キーワード
		.kkrs-search-input--keyword {
			&:after {
				content: "";
				background: url(../../images/1.0/ico-freeword.svg) no-repeat center center;//whitelabel-gadgetとgadgetで共通のパスにする
				background-size: 20px auto;
			}
		}

		//パネルを開く
		&.kkrs-active {
			.kkrs-search-panel:not(.kkrs-search-panel--suggest) {
				display: block;
			}

			//枠線の色を変更
			.kkrs-search-column {
				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 3px;
					position: absolute;
					top: calc(56px - 3px);
					left: 0;
					background: $color-btn;
					z-index: 1;
				}
			}
		}

		//サジェストパネルを開く
		&.kkrs-entering {
			.kkrs-search-panel--suggest {
				display: block;
			}
		}

		//入力済み
		&.kkrs-completed {

			//ラベルを表示
			.kkrs-search-label {
				display: flex;
			}

			//ラベル表示分の余白を確保
			.kkrs-search-input--place input,
			.kkrs-search-input--period input,
			.kkrs-search-input--date input {
				padding-top: 20px;
			}

			//出発日,帰着日の→
			.kkrs-search-input--period:before {
				margin-top: 10px;
			}
		}
	}

	//表示順を調整-------
	.kkrs-search-item--depPlace {
		order: -6;
	}

	.kkrs-search-item--arrPlace {
		order: -5;
	}

	.kkrs-search-item--people {
		order: -4;
	}

	.kkrs-search-item--period {
		order: -3;
	}

	.kkrs-search-item--checkin {
		order: -2;
	}

	.kkrs-search-item--keyword {
		order: -1;
	}

	//------------------
	.kkrs-search-item--depPlace,
	.kkrs-search-item--arrPlace {
		.kkrs-search-label {
			display: flex;
			align-items: center;

			&:after {
				content: "必須";
				display: inline-block;
				font-size: 11px;
				font-weight: normal;
				line-height: 1;
				padding: 2px 8px;
				margin-left: 10px;
				border-radius: 20px;
				background: #fff;
				border: 1px solid $secondary_red;
				color: $secondary_red;
			}
		}

		//出発地と目的地にマウスオーバーした時点でレコメンドを表示
		&:hover {
			.kkrs-search-panel--recommend {
				display: block;
			}
		}
	}

	//帰着日のラベル位置調整
	.kkrs-search-item--period {
		.kkrs-search-label:nth-child(2) {
			left: calc(50% + 10px);
		}
	}

	// //出発日、帰着日のインプットにクリアボタンを追加
	// //  (HTMLでは出発日のカレンダーを使いまわしているが、Reactでは出発日, 帰着日それぞれにカレンダーを持つため帰着日の表示位置を調整)
	// .kkrs-search-item--depDate,
	// .kkrs-search-item--returnDate,
	// .kkrs-search-item--checkin {
	// 	.kkrs-search-input {
	// 		display: inline-flex;
	// 		width: 100%;

	// 		input {
	// 			width: 100%;
	// 			min-width: 0;
	// 		}

	// 		.kkrs-input-adornment {
	// 			display: block;
	// 			min-width: 13px;
	// 			margin: 0 20px;

	// 			.kkrs-input-clear-btn {
	// 				position: absolute;
	// 				top: 50%;
	// 				margin-top: -0.5em;
	// 				font-size: 16px;

	// 				span:before {
	// 					font-family: "kkrs-dp";
	// 					font-size: 13px;
	// 					color: #ccc;
	// 					content: "\e911";
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	// //帰着日カレンダーが見切れるためrightを設定
	// //(HTMLでは出発日のカレンダーを使いまわしているが、Reactでは出発日, 帰着日それぞれにカレンダーを持つため帰着日の表示位置を調整)
	// .kkrs-search-item--returnDate {
	// 	.kkrs-search-panel--calendar {
	// 		left: auto;
	// 		right: -1px;
	// 	}
	// }

	//------------------------------
	//レンタカーチェック
	.kkrs-search-item--check {
		width: 100%;
		display: flex;
		justify-content: center;

		.kkrs-form-checkbox {
			display: flex;
			align-items: center;

			label {
				display: flex;
				align-items: center;
				margin-left: 10px;

				.kkrs-ico-car {
					color: $dark_gray;
					font-size: 20px;
					line-height: 1;
					margin-right: .3em;

					&:before {
						@include material_icon("\e531");
					}
				}
			}
		}
	}
}

@include mq(sp) {
	.kkrs-top-search-section {
		padding: 15px 10px 20px;

		.kkrs-pc {
			display: none;
		}

		.kkrs-sp {
			display: block;
		}

		//検索コンテナ------------------------
		.kkrs-search-wrap {
			display: block;

			&__contents {
				height: auto;
			}

			&__navi {
				width: auto;
				display: block;
			}
		}

		//検索ボタン
		.kkrs-top-search-submit-btn {
			width: 100%;
		}

		//kkrs-search-list 検索リスト------------------------
		.kkrs-search-list {
			display: block;
		}

		.kkrs-search-item {
			width: auto;

			//チェック項目以外の装飾
			&:not(.kkrs-search-item--check) {
				margin: 0 0 15px 0;
			}

			&.kkrs-active {
				.kkrs-search-column:before {
					top: calc(50px - 3px);
				}
			}

			.kkrs-search-label {
				top: 6px;
			}

			.kkrs-search-column {
				height: auto;
			}

			.kkrs-search-input {
				input {
					height: 48px;
				}
			}
		}

		//チェックイン日・泊数のツールチップを非表示（横スクロール防止）
		.kkrs-search-input--date .kkrs-search-clear-tooltip .kkrs-tooltip-wrap {
			display: none;
		}
	}
}

//食事条件のアイコンを表示（バナー・ガジェット作成ツールでの表示も兼用）
.kkrs-search-item--meal .kkrs-form-pulldown__set {
	padding-left: 38px;

	&:after {
		@include material_icon("\e56c");
		color: $dark_gray;
		position: absolute;
		top: 0;
		left: 0;
		width: 38px;
		height: 100%;
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

//--------------------------------------------
//kkrs-search-panel
//--------------------------------------------
.kkrs-search-panel {
	display: none; //初期非表示
	background-color: #fff;
	min-width: 100%;
	box-shadow: 0 3px 6px 0 rgba(#000, .2);
	@include abs_tl(100%, 0);
	padding: 10px 10px 30px 10px;
	z-index: 11;
}

@include mq(sp) {
	.kkrs-search-panel {
		max-width: 100%;

		//出発地、目的地リコメンド----------
		&--recommend {
			//下部に別で設定
		}
	}

	//出発地のレコメンド,サジェスト
	.kkrs-search-item--depPlace {
		.kkrs-search-panel--recommend {
			left: 0;
			right: auto;
		}
	}

	//目的地のレコメンド,サジェスト
	.kkrs-search-item--arrPlace {
		.kkrs-search-panel--recommend {
			left: auto;
			right: 0;
		}

		.kkrs-search-panel--suggest {
			left: auto;
			right: 0;
		}
	}
}

//閉じるボタン
.kkrs-search-panel-close-btn {
	background: transparent;
	border: none;
	position: absolute;
	top: 0 !important;
	right: 0 !important;
	width: 40px !important;
	height: 40px !important;
	line-height: 1;
	z-index: 10;

	&:before {
		@include material_icon("\e5cd");
		font-size: 18px;
		color: #95999e;
	}
}
//--------------------------------------------
//標準横型,標準縦型 共通
//--------------------------------------------
.kkrs-horizontal,
.kkrs-vertical {
	.kkrs-top-search-section {
		padding: 0;

		@include mq(pc) {
			font-size: 13px;

			.kkrs-search-item {
				margin-bottom: 10px;
				order: initial !important;

				.kkrs-search-label {
					top: 6px;
					font-size: 11px;

					&:after {
						padding: 1px 5px;
					}
				}

				.kkrs-search-column {
					height: 50px;

					&:before {
						top: calc(50px - 3px);
					}
				}

				.kkrs-search-input {
					input {
						font-size: 13px;
						height: 48px;
					}
				}

				.kkrs-form-pulldown {
					&__set {
						font-size: 13px;
						height: 48px;
					}
				}

				.kkrs-input-clear-btn {
					min-width: 30px;
				}
			}

			.kkrs-top-search-submit-btn {
				font-size: 16px;
				height: 40px;
				width: 240px;
				padding: 0;
			}
		}

		//ガジェット幅からモーダルがはみ出ないようにする
		@include mq(pc) {
			.kkrs-search-panel {
				max-width: 440px;

				.kkrs-select-area {
					width: 100%;
				}
			}

			.kkrs-search-big-panel-fix-btn {
				font-size: 16px;
			}

			//カレンダーの調整
			.kkrs-panel-calendar {
				width: 440px;
			}

			.kkrs-calendar-header {
				font-size: 12px;

				em {
					font-size: 14px;
				}
			}

			.kkrs-calendar-column {
				padding: 10px 5px;
			}

			.kkrs-calendar-tbl {
				thead {
					th {
						font-size: 11px;
						padding: 5px 0;
					}
				}

				tbody {
					td {
						font-size: 12px;
					}
				}
			}

			.kkrs-calendar__date {
				height: 24px;

				a {
					width: 24px;
					height: 24px;
					line-height: 24px;
				}
			}
			//「帰着日を選択してください」メッセージの調整
			.kkrs-calendar-date-popup {
				padding: 4px 6px;
				font-size: 11px;

				&::before {
					bottom: -6px;
					margin-left: -6px;
					border-width: 6px 6px 0;
				}
			}

			//出発日,帰着日カレンダー内の出発日,帰着日フッターの表示調整
			.kkrs-calendar-footer {
				margin-top: 5px;
				padding-top: 15px;

				.kkrs-calendar-period {
					padding: 8px 12px;
				}

				.kkrs-calendar-period__item {
					width: 80px;
				}

				.kkrs-calendar-period__item:last-child {
					width: 40px;
				}

				.kkrs-calendar-period__display {
					&.kkrs-current {
						border-bottom-width: 2px;
					}
				}

				.kkrs-calendar-period__title {
					font-size: 11px;
					margin-bottom: 0;
				}

				.kkrs-calendar-period__body {
					font-size: 12px;
				}

				.kkrs-calendar-period__arrow {
					padding: 0 8px 0 0;

					&:before {
						font-size: 16px;
					}
				}

				.kkrs-calendar-period__night {
					font-size: 12px;
				}

				.kkrs-search-big-panel-fix-btn {
					width: 100px;
				}
			}

			//spinboxの調整
			.kkrs-spinbox {
				width: 100px;
				min-width: 100px;
				padding: 0 30px;

				.kkrs-spinbox__btn-minus,
				.kkrs-spinbox__btn-plus {
					width: 24px;
					height: 24px;

					&:before {
						font-size: 20px;
					}
				}

				.kkrs-spinbox__number {
					font-size: 16px;
				}
			}

			//部屋数・人数選択の調整
			.kkrs-panel-select-people {

				.kkrs-select-room__item,
				.kkrs-select-guest__item {
					margin-bottom: 12px;
				}

				.kkrs-select-room__label,
				.kkrs-select-guest__label {
					font-size: 13px;

					small {
						font-size: 11px;
					}
				}

				.kkrs-select-room__type,
				.kkrs-select-guest__type {
					font-size: 11px;
				}

				.kkrs-alert-popup {
					font-size: 12px;
					max-width: 180px;
					min-width: 120px;
				}
			}

			//泊数の調整
			.kkrs-panel-calendar--checkin {
				.kkrs-search-big-panel__footer {
					padding: 15px 0 0;
				}

				.kkrs-selected-date__night-label {
					font-size: 14px;
				}

				.kkrs-search-big-panel-fix-btn {
					width: 160px;
				}
			}
		}
	}
}

//--------------------------------------------
//標準横型
//--------------------------------------------
/* 標準横型 */
.kkrs-horizontal {
	.kkrs-top-search-section {
		@include mq(pc) {
			.kkrs-search-item {
				width: calc(50% - 6px);

				&--arrPlace {
					.kkrs-search-panel--recommend {
						left: auto;
						right: 0;
					}
				}

				&--check {
					width: 100%;
				}
			}
			.kkrs-search-item--period,
			.kkrs-search-item--checkin {
				width: 100%;
			}
		}
	}
}

//--------------------------------------------
//標準縦型 vertical
//--------------------------------------------
/* 標準縦型 */
.kkrs-vertical {
	.kkrs-top-search-section {
		@include mq(pc) {
			.kkrs-search-item {
				width: 100%;
			}
		}
	}
}